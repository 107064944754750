import React, { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { DataGrid, GridColDef, GridFooter, GridFooterContainer, GridRowId, GridSlotsComponentsProps, GridToolbarContainer, GridToolbarQuickFilter } from '@mui/x-data-grid';
import { Button, Dialog, DialogContent, DialogTitle, Divider, IconButton, TextField, Typography } from '@mui/material';
import { callApi } from '../utils/request';
import AddIcon from '@mui/icons-material/Add';
import ConfirmAction from './ConfirmAction';
import Alert from './Alert';

function SimpleDataGrid({ name, label }: { name: string, label: string }) {
    const [data, setData] = useState<{ id: number, label: string }[]>([])
    const [formOpened, setFormOpened] = useState<boolean>(false)
    const [editformOpened, setEditFormOpened] = useState<boolean>(false)
    const [update, setUpdate] = useState<boolean>(false)
    const [selectedDataId, setSelectedDataId] = useState<GridRowId | null>(null)
    const [alert, setAlert] = useState<CustomAlertType | null>(null)
    const [deleteConfirm, setDeleteConfirm] = useState<boolean>(false)

    useEffect(() => {
        callApi('get', `/manage/${name}/search`).then(r => {
            setData(r)
        }, () => { })
    }, [name, update])

    const handleUpdate = () => {
        setUpdate(!update)
    }

    const handleDelete = (id: GridRowId) => {
        callApi('delete', `/manage/${name}/${id}`).then(r => {
            if (r.alert) {
                setAlert(r.alert)
            }
        }, () => { })
    }

    const columns: GridColDef[] = [
        { field: 'id', headerName: 'ID', width: 90, align: 'center' },
        {
            field: 'label',
            headerName: 'Intitulé',
            flex: 1
        }
    ];

    const CustomToolbarComponent = () => {
        return (
            <GridToolbarContainer>
                <IconButton size='large' sx={{ width: 'auto' }} onClick={() => { setFormOpened(true) }}>
                    <AddIcon />
                </IconButton>
                <GridToolbarQuickFilter />
                <AddForm
                    open={formOpened}
                    onClose={() => { handleUpdate(); setFormOpened(false) }}
                    setAlert={setAlert}
                    name={name}
                />
            </GridToolbarContainer>
        )
    }

    const CustomFooterComponent = (
        props: NonNullable<GridSlotsComponentsProps['footer']>,
    ) => {
        return (
            <GridFooterContainer sx={{ justifyContent: selectedDataId ? 'space-between' : 'end' }}>
                {
                    selectedDataId
                        ? <Box sx={{ display: 'flex', gap: 1 }}>
                            <Button
                                variant='outlined'
                                size='small'
                                sx={{ ml: 1 }}
                                onClick={() => { setEditFormOpened(true) }}
                            >
                                Editer
                            </Button>
                            <Button
                                variant='outlined'
                                color='error'
                                size='small'
                                onClick={() => {
                                    setDeleteConfirm(true)
                                }}
                            >
                                Supprimer
                            </Button>
                            <ConfirmAction
                                open={deleteConfirm}
                                setOpen={setDeleteConfirm}
                                onClose={(confirmation) => {
                                    confirmation && handleDelete(selectedDataId)
                                    confirmation && handleUpdate()
                                }}
                                title="Suppression de data"
                                description="Etes vous sur de bien vouloir supprimer cet élément ? Cette action est irréversible."
                            />
                        </Box>
                        : null
                }
                <GridFooter sx={{ border: 'none' }} />
            </GridFooterContainer>
        );
    }

    return (
        <Box sx={{ height: 'calc(100vh - 64px - 24px * 5)', width: '100%' }}>
            <Typography variant='h6'>{label}</Typography>
            <Divider sx={{ my: 2 }} />
            <DataGrid
                sx={{ width: {sm: "100%", md: "90%", lg: "80%", xl: "70%"} }}
                rows={data}
                columns={columns}
                autoPageSize
                slots={{
                    toolbar: CustomToolbarComponent, footer: CustomFooterComponent
                }}
                onRowSelectionModelChange={(rowSelection) => {
                    setSelectedDataId(rowSelection[0])
                }}
            />
            <AddForm
                open={editformOpened}
                onClose={() => { handleUpdate(); setEditFormOpened(false) }}
                currentData={data.filter(i => i.id === selectedDataId)[0]}
                setAlert={setAlert}
                name={name}
            />
            {alert && <Alert alert={alert} />}
        </Box>
    );
}

function AddForm({ open, onClose, currentData = null, setAlert, name }: SimpleAddFormProps) {
    const [data, setData] = useState<AddFormDataType | null>(null)

    useEffect(() => {
        setData(currentData ? currentData : {
            label: ''
        })
    }, [currentData])

    const handleClose = () => {
        onClose();
    };

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        if (data?.label) {
            callApi(
                currentData ? 'put' : 'post',
                `/manage/${name}/${currentData ? currentData.id : ''}`,
                data
            ).then(r => {
                handleClose()
                if (r.alert) {
                    setAlert(r.alert)
                }
            }, () => { })
        }
    }

    const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setData({ ...data!, [e.target.name]: e.target.value })
    }

    return (
        <Dialog onClose={handleClose} open={open} sx={{ p: 3 }}>
            <DialogTitle>{currentData ? 'Editer' : 'Ajouter'} un data</DialogTitle>
            <DialogContent>
                <Box
                    component='form'
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 2,
                        pt: 1
                    }}
                    onSubmit={handleSubmit}
                >
                    <TextField
                        value={data?.label || ''}
                        name='label'
                        onChange={handleChange}
                        label='Nom'
                    />
                    <Button
                        variant='outlined'
                        color='success'
                        type='submit'
                    >
                        Enregistrer
                    </Button>
                </Box>
            </DialogContent>
        </Dialog>
    );
}

export default SimpleDataGrid;
