import React, { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { callApi } from '../utils/request';
import { DataGrid, GridColDef, GridFooter, GridFooterContainer, GridRowId, GridSlotsComponentsProps, GridToolbarContainer, GridToolbarQuickFilter } from '@mui/x-data-grid';
import { Autocomplete, Box, Button, Dialog, DialogContent, DialogTitle, Divider, IconButton, TextField, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import Alert from '../components/Alert';
import ConfirmAction from '../components/ConfirmAction';

function TrainersList() {
  const [trainers, setTrainers] = useState<TrainerType[]>([])
  const [formOpened, setFormOpened] = useState<boolean>(false)
  const [editformOpened, setEditFormOpened] = useState<boolean>(false)
  const [update, setUpdate] = useState<boolean>(false)
  const [selectedTrainerId, setSelectedTrainerId] = useState<GridRowId | null>(null)
  const [alert, setAlert] = useState<CustomAlertType | null>(null)
  const [deleteConfirm, setDeleteConfirm] = useState<boolean>(false)

  const handleUpdate = () => {
    setUpdate(!update)
  }

  const handleDelete = (id: GridRowId) => {
    callApi('delete', '/manage/trainer/' + id).then(r => {
      if (r.alert) {
        setAlert(r.alert)
      }
    }, () => { })
  }

  useEffect(() => {
    callApi('get', '/manage/trainer/search').then(r => {
      const t = r.map((i: TrainerType) => {
        return { ...i, documents: JSON.parse(i.documents) }
      })
      setTrainers(t)

    }, () => { })
  }, [update])

  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 150, align: 'center' },
    {
      field: 'firstName',
      headerName: 'Prénom',
      maxWidth: 260,
      flex: 1
    },
    {
      field: 'lastName',
      headerName: 'Nom',
      maxWidth: 260,
      flex: 1
    },
    {
      field: 'email',
      headerName: 'Email',
      flex: 2
    }
  ];

  const CustomToolbarComponent = () => {
    return (
      <GridToolbarContainer>
        <IconButton size='large' sx={{ width: 'auto' }} onClick={() => { setFormOpened(true) }}>
          <AddIcon />
        </IconButton>
        <GridToolbarQuickFilter />
        <TrainerForm
          open={formOpened}
          onClose={() => { handleUpdate(); setFormOpened(false) }}
          setAlert={setAlert}
        />
      </GridToolbarContainer>
    )
  }

  const CustomFooterComponent = (
    props: NonNullable<GridSlotsComponentsProps['footer']>,
  ) => {
    return (
      <GridFooterContainer sx={{ justifyContent: selectedTrainerId ? 'space-between' : 'end' }}>
        {
          selectedTrainerId
            ? <Box sx={{ display: 'flex', gap: 1 }}>
              <Button
                variant='outlined'
                size='small'
                sx={{ ml: 1 }}
                onClick={() => { setEditFormOpened(true) }}
              >
                Editer
              </Button>
              <Button
                variant='outlined'
                color='error'
                size='small'
                onClick={() => {
                  setDeleteConfirm(true)
                }}
              >
                Supprimer
              </Button>
              <ConfirmAction
                open={deleteConfirm}
                setOpen={setDeleteConfirm}
                onClose={(confirmation) => {
                  confirmation && handleDelete(selectedTrainerId)
                  confirmation && handleUpdate()
                }}
                title="Suppression du formateur"
                description="Etes vous sur de bien vouloir supprimer ce formateur ? Cette action est irréversible."
              />
            </Box>
            : null
        }
        <GridFooter sx={{ border: 'none' }} />
      </GridFooterContainer>
    );
  }

  return (
    <Box sx={{ height: 'calc(100vh - 64px - 24px * 5)', width: '100%' }}>
      <Typography variant='h6'>Formateurs</Typography>
      <Divider sx={{ my: 2 }} />
      <DataGrid
        sx={{ width: {sm: "100%", md: "90%", lg: "80%", xl: "70%"} }}
        rows={trainers}
        columns={columns}
        autoPageSize
        slots={{
          toolbar: CustomToolbarComponent, footer: CustomFooterComponent
        }}
        onRowSelectionModelChange={(rowSelection) => {
          setSelectedTrainerId(rowSelection[0])
        }}
      />
      <TrainerForm
        open={editformOpened}
        onClose={() => { handleUpdate(); setEditFormOpened(false) }}
        currentTrainer={trainers.filter(i => i.id === selectedTrainerId)[0]}
        setAlert={setAlert}
      />
      {alert && <Alert alert={alert} />}
    </Box>
  );
}

function TrainerForm({ open, onClose, currentTrainer, setAlert }: TrainerFormProps) {
  const [trainer, setTrainer] = useState<TrainerType | null>(null)
  const [documentsList, setDocumentsList] = useState<AppDocumentType[]>([])

  useEffect(() => {
    const initialState = {
      firstName: '',
      lastName: '',
      email: '',
      documents: []
    }
    callApi('get', '/manage/document').then(r => {
      setDocumentsList(r)

    }, () => { })
    setTrainer(currentTrainer ? { ...currentTrainer, documents: JSON.parse(currentTrainer.documents) || [] } : initialState)
  }, [currentTrainer])

  const handleClose = () => {
    onClose();
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (trainer?.firstName && trainer.lastName && trainer.email) {
      callApi(
        currentTrainer ? 'put' : 'post',
        '/manage/trainer/' + (currentTrainer ? currentTrainer.id : ''),
        trainer
      ).then(r => {
        handleClose()
        if (r.alert) {
          setAlert(r.alert)
        }
      }, () => { })
    }
  }

  const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setTrainer({ ...trainer!, [e.target.name]: e.target.value })
  }

  return (
    <Dialog onClose={handleClose} open={open} sx={{ p: 3 }}>
      <DialogTitle>{currentTrainer ? 'Editer' : 'Ajouter'} un formateur</DialogTitle>
      <DialogContent>
        <Box
          component='form'
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            pt: 1
          }}
          onSubmit={handleSubmit}
        >
          <TextField
            value={trainer?.firstName || ''}
            name='firstName'
            onChange={handleChange}
            label='Prénom'
          />
          <TextField
            value={trainer?.lastName || ''}
            name='lastName'
            onChange={handleChange}
            label='Nom'
          />
          <TextField
            value={trainer?.email || ''}
            name='email'
            onChange={handleChange}
            label='Email'
          />
          <Autocomplete
            multiple
            id="trainer-documents"
            options={documentsList}
            getOptionLabel={(option) => option.label}
            value={documentsList.filter((d: any) => {
              return trainer?.documents.some((e: any) => e.id === d.id)
            })}
            onChange={(e, v) => { setTrainer({ ...trainer!, documents: v }) }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                label="Documents"
                placeholder="Documents a fournir"
              />
            )}
          />
          <Button
            variant='outlined'
            color='success'
            type='submit'
          >
            Enregistrer
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
}

export default TrainersList;
