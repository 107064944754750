import * as React from 'react';
import Box from '@mui/material/Box';
import { DataGrid, GridColDef, GridFooter, GridFooterContainer, GridRowId, GridSlotsComponentsProps, GridToolbarContainer, GridToolbarQuickFilter, GridValueGetterParams } from '@mui/x-data-grid';
// import DoneIcon from '@mui/icons-material/Done';
// import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
// import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import { Button, Divider, IconButton, Typography } from '@mui/material';
import { callApi } from '../utils/request';
import { useNavigate } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';

const columns: GridColDef[] = [
  { field: 'id', headerName: 'Ref', minWidth: 80, maxWidth: 310, flex: 1 },
  {
    field: 'fullName',
    headerName: 'Formateur',
    description: 'Nom complet formateur',
    maxWidth: 140,
    valueGetter: (params: GridValueGetterParams) =>
      `${params.row.Trainer.firstName || ''} ${params.row.Trainer.lastName || ''}`,
    flex: 1,
  },
  {
    field: 'email',
    headerName: 'Email',
    maxWidth: 260,
    flex: 1,
    valueGetter: ({ row }) => `${row.Trainer.email}`
  },
  {
    field: 'label',
    headerName: 'Mission',
    flex: 1,
    valueGetter: ({ row }) => `${row.Label?.label || ''}`
  }
];

// function getStateIcon(state: StateContractEnumType) {
//   switch (state) {
//     case "compliant":
//       return <DoneIcon titleAccess='Conforme' color='success' />

//     case "pending":
//       return <MoreHorizIcon titleAccess='En attente' color='warning' />

//     case "notcompliant":
//       return <PriorityHighIcon titleAccess='Non conforme' color='error' />
//   }
// }

export default function EditableContractList() {
  const [data, setData] = React.useState<ContractType[]>([])
  const [selectedContractId, setSelectedContractId] = React.useState<GridRowId | null>(null)
  const navigate = useNavigate()
  const [refresh, setRefresh] = React.useState<boolean>(false)

  React.useEffect(() => {
    callApi('get', '/manage/contract/search').then((r) => {
      setData(r)
    }, () => { })
  }, [refresh])

  const CustomFooterComponent = (
    props: NonNullable<GridSlotsComponentsProps['footer']>,
  ) => {
    return (
      <GridFooterContainer sx={{ justifyContent: selectedContractId ? 'space-between' : 'end' }}>
        {
          selectedContractId
            ? <Box display={"flex"} gap={1}>
              <Button
                variant='outlined'
                size='small'
                sx={{ ml: 1 }}
                onClick={() => { navigate(`/dashboard/editables-contracts/${selectedContractId}`) }}
              >
                Editer
              </Button>
              <Button
                variant="outlined"
                color="error"
                size="small"
                onClick={() => {
                  callApi("delete", `/manage/contract/${selectedContractId}`).then(r => {
                    setRefresh(!refresh)
                  })
                }}
              >
                Supprimer
              </Button>
            </Box>
            : null
        }
        <GridFooter sx={{ border: 'none' }} />
      </GridFooterContainer>
    );
  }

  const CustomToolbarComponent = () => {
    return (
      <GridToolbarContainer>
        <IconButton size='large' sx={{ width: 'auto' }} onClick={() => { navigate('/dashboard/create-contract') }}>
          <AddIcon />
        </IconButton>
        <GridToolbarQuickFilter />
      </GridToolbarContainer>
    )
  }

  return (
    <Box sx={{ height: 'calc(100vh - 64px - 24px * 5)', width: '100%' }}>
      <Typography variant='h6'>Contrats éditables</Typography>
      <Divider sx={{ my: 2 }} />
      <DataGrid
        sx={{ width: {sm: "100%", md: "90%", lg: "80%", xl: "70%"} }}
        onRowDoubleClick={params => { navigate(`/dashboard/editables-contracts/${params.row.id}`) }}
        rows={data}
        columns={columns}
        slots={{ toolbar: CustomToolbarComponent, footer: CustomFooterComponent }}
        slotProps={{
          toolbar: {
            showQuickFilter: true
          },
        }}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 5,
            },
          },
        }}
        onRowSelectionModelChange={(rowSelection) => {
          setSelectedContractId(rowSelection[0])
        }}
        autoPageSize
      />
    </Box>
  );
}
