import axios from "axios"
const mainApi = process.env.REACT_APP_API_URL;

export const callApi = (method: AxiosMethodsType, url: string, data: Object | null = null, isBlob = false) => {
    return new Promise<any>(async (resolve, reject) => {
        const headers = { 'Authorization': JSON.parse(localStorage.getItem('user') as string)?.token || null }
            const result = await axios({
                method: method,
                headers: headers,
                url: mainApi + url,
                data: data,
                responseType: isBlob ? 'blob' : 'json',
                
            }).then(res => {
                if (res.data) {
                    return resolve(res.data)
                }
            }).catch((err) => {
                if (err.response?.status === 401) {
                    localStorage.removeItem('user')
                }
                return reject({ errorStatus: err.response?.status })
            })

            return reject(result)
    })   
}

export const callApiFetch = (method: AxiosMethodsType, url: BodyInit, body: any) => {
    return new Promise<any>(async (resolve, reject) => {
        const headers = { 'Authorization': JSON.parse(localStorage.getItem('user') as string)?.token || null }
            const result = await fetch(mainApi! + url,
            {
                method: method,
                headers: headers,
                body: method !== 'get' ? body : undefined,
            }).then(res => res.json())
            .then(body => {
                return resolve(body)
            }).catch((err) => {
                if (err.response?.status === 401) {
                    localStorage.removeItem('user')
                }
                return reject({ errorStatus: err.response?.status })
            })

            return reject(result)
    })
    
}