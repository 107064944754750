import Papa from 'papaparse';
import moment from 'moment';
import 'moment-timezone';
import 'moment/locale/fr';

export const CsvToCal = (csv:string):any => {
    /**
     * Le CSV brut.
     *
     * results.data est le CSV brut, dans lequel les données sont présentées
     * de la façon suivante.
     *
     * 0  : @param Facture    integer : (bill_num) numéro de la facture.
     * 1  : @param CR         bool : indique si un compte rendu est présent.
     * 2  : @param Note       bool : indique si les notes ont été saisies.
     * 3  : @param Comment    bool : indique si les commentaires ont été saisis.
     * 4  : @param Contrat    string : (bill_cmd) le numéro de contrat client.
     * 5  : @param Campus     string : (bill_campus) le label indiquant le campus.
     * 6  : @param Promo      string : (bill_classroom) le label indiquant la classe.
     * 7  : @param Horaire    string : la plage horaire.
     * 8  : @param Tarif      string : (bill_hrlprice) le prix à l'heure, format XYZ €.
     * 9  : @param H          real : (billd_volume) indique le volume d'heure de la prestation (unitaire).
     * 10 : @param D/P        char : indique si présentiel/distanciel.
     * 11 : @param Date       date : (billd_date_begin) indique la date d'exécution de la prestation (unitaire).
     * 12 : @param Intitulé   string : (billd_modname) indique le nom du module.
     * 13 : @param Formateur  string : (billd_trainer_fname billd_trainer_lname) indique le prénom et nom du formateur.
     * 14 : @param Société    string : La démonitation social de la société de sous-traitance.
     * 15 : @param PrixSS     real : le prix HT € de sous-traitance par unité.
     * 16 : @param Unité      char : l'unité utilisé pour déterminer le produit HT € de sous-traitance.
     * 17 : @param PrixMax    real : prix HT € max.
     * 18 : @param ContratLS  string : le numéro (ou libellé) du contrat.
     * 19 : @param Notes      string : commentaires éventuels.
     * 
     * Note :
     *    Seuls les indices suivants doivent être utilisés :
     *      - 5, 6, 7, 9, 10, 11, 12 et 13 pour la partie planification.
     *      - (les précédentes) + 14, 15, 16 et 18 pour la partie contrat.
    */
    
    try {
        const results = Papa.parse(csv, {
            header: false,
            delimiter: "\t",
            skipEmptyLines: true,
            dynamicTyping: true
        });
    
        /**
         * contractMeta : Les méta-données d'un contrat.
         *
         * @param cnt_num         string : le numéro du contrat.
         * @param cnt_society     string : le nom de la société de sous-traitance (si vide : alors le formateur est en individuel).
         * @param cnt_price       number : le prix HT à l'unité négocié.
         * @param cnt_price_unit  char : l'unité de produit.
         * @param cnt_planif      array<{}> : les données de planification.
        */
        let contractMeta: {
            cnt_num: string | null,
            cnt_society: string | null
            cnt_price: number,
            cnt_price_unit: UnitContractEnumType,
            cnt_planif: any[]
            cnt_documents: AppDocumentType[]
        } = {
            cnt_num: null,
            cnt_society: null,
            cnt_price: 0,
            cnt_price_unit: null!,
            cnt_planif: [],
            cnt_documents: []
        };
    
        const idx_campus = 5,
            idx_promo = 6,
            idx_horaire = 7,
            idx_heure = 9,
            idx_config = 10,
            idx_date = 11,
            idx_mod = 12,
            idx_trainer = 13,
            idx_society = 14,
            idx_priceSociety = 15,
            idx_priceUnit = 16,
            idx_contractNum = 18;
    
        let enumTypeShort: { [key: string]: UnitContractEnumType } = {
            "J": "jour",
            "H": "heure",
            "F": "forfait"
        };
    
        // lastModname indique le nom du module courant.
        // Dans le cas où une ligne n'indique pas de module, alors lastModname
        // sera utilisé.
        // Lorsqu'une ligne indique un module, alors lastModname sera mis à jour
        // avec l'intitulé du module courant.
        let lastModname = "";
        results.data.forEach((line: any) => {
            //if (line[idx_contractNum]) contractMeta.cnt_num = (typeof line[idx_contractNum] === "string" ? line[idx_contractNum].replace(/\s/g, '') : line[idx_contractNum]);
            if (line[idx_contractNum]) contractMeta.cnt_num = line[idx_contractNum].replace(/\s/g, '').trim().toUpperCase();
    
            if (line[idx_society]) {
                contractMeta.cnt_society = line[idx_society].replace(/\s{2,}/g, ' ').trim().toUpperCase();
            }
    
            if (!contractMeta.cnt_price) contractMeta.cnt_price = parseFloat(line[idx_priceSociety].replace(/\s/g, '').replace('€', '').replace(',', '.'));
            if (!contractMeta.cnt_price_unit) contractMeta.cnt_price_unit = enumTypeShort[line[idx_priceUnit].replace(/\s/g, '').toUpperCase()[0]];
    
            if (0 === contractMeta.cnt_planif.length && !line[idx_mod]) {
                return new Error("Manque-t-il le nom de module ?");
            }
    
            lastModname = line[idx_mod] || lastModname;
    
            let date = moment(line[idx_date].replace(',', ' '), 'ddd DD MMM YY').unix(),
                fname = "", lname = "";
    
            let trainernames = line[idx_trainer].replace(/\s{2,}/g, '').split(' ');
            for (let i = 1; i < trainernames.length; i++) {
                if (trainernames[i].match(/[A-Z]+/g)) {
                    fname = trainernames.slice(0, i).join(' ');
                    lname = trainernames.slice(i).join(' ');
                    break;
                }
            }
    
            // Si individual (alors société null), alors le formateur est le signataire (même personne).
            contractMeta.cnt_society = contractMeta.cnt_society || `[INDIV]-${fname.toUpperCase()}|${lname.toUpperCase()}`;
    
            contractMeta.cnt_planif.push([
                line[idx_campus],
                line[idx_promo],
                line[idx_horaire],
                line[idx_config],
                date, // Le date de formation (jour).
                fname, // Le prénom du formateur.
                lname, // le nom de famille du formateur.
                parseFloat(typeof line[idx_heure] === "string" ? line[idx_heure].replace(/\s/g, '').replace(',', '.') : line[idx_heure]), // Volume journalier.
                line[idx_mod] || lastModname // Module de formation.
            ]);
        });
    
        return contractMeta;
    } catch (err) {
        return new Error("Impossible de parser le contenu CSV, veuillez verifier le format de votre import.");
        // return err;
    }
};