import React from 'react';
import { Route, Routes } from 'react-router-dom';
import EditableContractList from '../pages/EditableContractList';
import AuthAppWrapper from '../components/AuthAppWrapper';
import Dashboard from '../pages/Dashboard';
import ContractsList from '../pages/ContractsList';
import TrainersList from '../pages/TrainersList';
import SchoolsList from '../pages/SchoolsList';
import CampusesList from '../pages/CampusesList';
import PromotionsList from '../pages/PromotionsList';
import ContractForm from '../pages/ContractForm';
import DocumentsList from '../pages/DocumentsList';
import ContractInfo from '../pages/ContractInfo';
import LabelsList from '../pages/LabelList';
import NonComplianceReasonsList from '../pages/NonComplianceReasonsList';

function AuthenticatedRouter() {
  return (
    <Routes>
      <Route element={<AuthAppWrapper />}>
        <Route index element={<Dashboard />} />
        <Route path='/create-contract' element={<ContractForm />} />
        <Route path='/editables-contracts' element={<EditableContractList />} />
        <Route path='/editables-contracts/:id' element={<ContractForm />} />
        <Route path='/generated-contracts' element={<ContractsList />} />
        <Route path='/contract/:id' element={<ContractInfo />} />
        <Route path='/trainers' element={<TrainersList />} />
        <Route path='/schools' element={<SchoolsList />} />
        <Route path='/campuses' element={<CampusesList />} />
        <Route path='/promotions' element={<PromotionsList />} />
        <Route path='/documents' element={<DocumentsList />} />
        <Route path='/missions-labels' element={<LabelsList />} />
        <Route path='/non-compliance-reasons' element={<NonComplianceReasonsList />} />
      </Route>
    </Routes>
  );
}

export default AuthenticatedRouter;
