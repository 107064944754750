import { Autocomplete, FilterOptionsState, TextField, createFilterOptions } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { callApi } from '../utils/request';
import { JSX } from 'react/jsx-runtime';
import Alert from './Alert';

const filter = createFilterOptions<{
    inputValue?: string;
    label: string;
}>();

function AppAutocomplete({ onChange, name, relation, disabled = false, currentValue, label, creatable = false }: AppAutoCompleteProps) {
    const [options, setOptions] = useState<any[]>([])
    const [selectedValue, setSelectedValue] = useState(currentValue ? currentValue : null)
    const [alert, setAlert] = useState<CustomAlertType | null>(null)

    useEffect(() => {
        setSelectedValue(currentValue)
        callApi(
            'get',
            relation?.id ? `/manage/${name}/search?${relation.from}=${relation.id}` : `/manage/${name}/search`
        ).then((r) => {
            setOptions(r)
        }, () => { })
    }, [name, relation, currentValue])

    const handleChange = (e: React.SyntheticEvent, v: any) => {
        onChange(name, v ? { ...v, from: name } : null)
    }

    const creatableProps = {
        ...(creatable
            ? {
                filterOptions: (options: any[], params: FilterOptionsState<{ inputValue?: string | undefined; label: string; }>) => {
                    const filtered = filter(options, params);

                    const { inputValue } = params;
                    // Suggest the creation of a new value
                    const isExisting = options.some((option) => inputValue === option.label);
                    if (inputValue !== '' && !isExisting) {
                        filtered.push({
                            inputValue,
                            label: `Créer "${inputValue}"`,
                        });
                    }
                    return filtered;
                },
                getOptionLabel: (option: { inputValue: any; label: any; }) => {
                    // Value selected with enter, right from the input
                    if (typeof option === 'string') {
                        return option;
                    }
                    // Add "xxx" option created dynamically
                    if (option.inputValue) {
                        return option.inputValue;
                    }
                    // Regular option
                    return option.label;
                },
                renderOption: (
                    props: JSX.IntrinsicAttributes & React.ClassAttributes<HTMLLIElement> & React.LiHTMLAttributes<HTMLLIElement>,
                    option: { label: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | React.ReactFragment | React.ReactPortal | null | undefined; }
                ) => <li {...props}>{option.label}</li>,
                selectOnFocus: true,
                clearOnBlur: true,
                handleHomeEndKeys: true,
                freeSolo: true,
                onChange: (event: React.SyntheticEvent<Element, Event>, newValue: { inputValue: any; }) => {
                    if (typeof newValue === 'string') {
                        setSelectedValue({
                            label: newValue,
                        });
                    } else if (newValue && newValue.inputValue) {
                        // Create a new value from the user input
                        callApi('post', `/manage/${name}`, { label: newValue.inputValue, ...(relation && { [relation.from.charAt(0).toUpperCase() + relation.from.slice(1) + 'Id']: relation.id }) }).then(r => {
                            if (r.data.id) {
                                setSelectedValue({
                                    label: newValue.inputValue,
                                });
                                handleChange(event, r.data)
                            }
                            if (r.alert) {
                                setAlert(r.alert)
                            }
                        }, () => { })
                    } else {
                        handleChange(event, newValue);
                    }
                }
            }
            : {
                onChange: handleChange
            }
        )
    }

    return (
        <React.Fragment>
            <Autocomplete
                {...creatableProps}
                disablePortal
                options={options}
                sx={{ width: '100%' }}
                renderInput={(params) => <TextField {...params} label={label} />}
                // onChange={handleChange}
                disabled={disabled}
                value={selectedValue}
            />
            {alert && <Alert alert={alert} />}
        </React.Fragment>
    );
}

export default AppAutocomplete;
