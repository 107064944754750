import { useState } from "react";
import {
  Grid,
  TextField,
  Typography,
  Box,
  Card,
  CardContent,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Button,
} from "@mui/material";
import { BsCircleFill } from "react-icons/bs";
import { callApi } from "../utils/request";
import AppAutocomplete from "./AppAutocomplete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import axios from "axios";
import Alert from "./Alert";

export default function CompliancesForm(props: any) {
  const { Document: doc, ...GeneratedContractDocument } = props.category;
  const { state, setState } = props;
  const [documentData, setDocumentData] = useState(GeneratedContractDocument);
  const [alert, setAlert] = useState<CustomAlertType | null>(null)

  const [haveComment, setHaveComment] = useState(
    ![null, ""].includes(GeneratedContractDocument.comment)
  );

  const handleSubmit = (tmp: any) => {
    callApi(
      "post",
      `/manage/contract/generated/${documentData.GeneratedContractId}/${documentData.DocumentId}`,
      tmp ?? documentData
    )
    .then(r => {
      if (r.alert) {
        setAlert(r.alert)
        if (r.alert.variant === "success") {
          setState({ ...(tmp ?? documentData) }.state);
        }
      }
    })
    .catch(() => { });
  };

  const compliances: any = {
    valid: { label: "Conforme" },
    conflict: { label: "Non conforme" },
    wait: { label: "En attente de validation" },
  };

  return (
    <>
      {alert && <Alert alert={alert} />}
      <Card elevation={3} sx={{ borderRadius: 4, position: "relative" }}>
        <CardContent sx={{ mt: 2, display: "flex", flexDirection: "column" }}>
          <Box display="flex" flexDirection="row">
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <BsCircleFill
                size={30}
                color={
                  documentData.state === "valid"
                    ? "green"
                    : documentData.state === "conflict"
                      ? "red"
                      : ["wait", null].includes(documentData.state)
                        ? "orange"
                        : "grey"
                }
              />
            </Box>
            <Typography
              variant="h6"
              sx={{ width: "100%", textAlign: "center" }}
            >
              {doc.label}
              {!GeneratedContractDocument.url && (
                <Typography color="grey" textAlign="center">
                  En attente du document
                </Typography>
              )}
            </Typography>
          </Box>
          <Box display={"flex"} justifyContent={"center"} gap={1}>
            <Button
              sx={{ mt: 2 }}
              fullWidth
              variant="outlined"
              disabled={!GeneratedContractDocument.url}
              onClick={async () => {
                const content = await axios({
                  method: "get",
                  headers: { Authorization: `${localStorage.getItem("token")}` },
                  url: `/manage/contract/generated/document/${GeneratedContractDocument.GeneratedContractId}/${GeneratedContractDocument.url}`,
                  baseURL: process.env.REACT_APP_API_URL,
                  responseType: "blob",
                }).then((res) => {
                  if (res.data) {
                    return res.data;
                  }
                });
                if (content) {
                  const url = window.URL.createObjectURL(content);
                  window.open(url, `Document : ${doc.label}`)
                }
              }}
            >
              Ouvrir
            </Button>
            <Button
              sx={{ mt: 2 }}
              fullWidth
              variant="outlined"
              color="secondary"
              disabled={!GeneratedContractDocument.url}
              onClick={async () => {
                const content = await axios({
                  method: "get",
                  headers: { Authorization: `${localStorage.getItem("token")}` },
                  url: `/manage/contract/generated/document/${GeneratedContractDocument.GeneratedContractId}/${GeneratedContractDocument.url}`,
                  baseURL: process.env.REACT_APP_API_URL,
                  responseType: "blob",
                }).then((res) => {
                  if (res.data) {
                    return res.data;
                  }
                });
                if (content) {
                  const url = window.URL.createObjectURL(content);
                  const link = document.createElement("a");
                  link.href = url;
                  link.setAttribute(
                    "download",
                    GeneratedContractDocument.filename
                  );
                  document.body.appendChild(link);
                  link.click();
                  link.parentNode!.removeChild(link);
                }
              }}
            >
              Télécharger
            </Button>
          </Box>
          <FormControl fullWidth sx={{ mt: 2 }}>
            <InputLabel id="demo-simple-select-label">Conformité</InputLabel>
            <Select
              labelId="compliance-state-select"
              disabled={state === "enclosed" ? true : (state === "compliant" || !GeneratedContractDocument.url)}
              value={documentData.state ? documentData.state : "wait"}
              label="Conformité"
              onChange={(state) => {
                const tmp = {
                  ...documentData,
                  state: state.target.value,
                };
                setDocumentData(tmp);
                handleSubmit(tmp);
              }}
            >
              {Object.entries(compliances).map((c: any) => {
                return (
                  <MenuItem key={c[0]} value={c[0]}>
                    {c[1].label}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>

          <Grid
            item
            xs={12}
            sx={{ gap: 2, display: "flex", flexDirection: "column", mt: 2 }}
          >
            {documentData.state === "conflict" && (
              <AppAutocomplete
                name="non-compliance-reason"
                currentValue={documentData.NonComplianceReason}
                onChange={(event, newInputValue) => {
                  let tmp = {
                    ...documentData,
                    NonComplianceReason: newInputValue,
                  }
                  setDocumentData(tmp);
                  handleSubmit(tmp);
                }}
                label="Raison de non conformite"
                creatable
              />
            )}
            {haveComment && (
              <TextField
                sx={{ width: "100%" }}
                label="Commentaire"
                name="commentaire"
                disabled={state === "compliant"}
                value={documentData.comment || ""}
                multiline
                rows={4}
                onChange={(e) => {
                  const tmp = { ...documentData, comment: e.target.value };
                  setDocumentData(tmp);
                  handleSubmit(tmp);
                }}
              />
            )}
            {documentData.state === "conflict" && (
              <Box
                display="flex"
                flexDirection="row"
                onClick={(e) => {
                  setHaveComment(!haveComment);
                }}
                sx={{ cursor: "pointer" }}
              >
                {haveComment ? (
                  <RemoveIcon color="primary" sx={{ mr: 1 }} />
                ) : documentData.comment ? (
                  <VisibilityIcon color="primary" sx={{ mr: 1 }} />
                ) : (
                  <AddIcon color="primary" sx={{ mr: 1 }} />
                )}
                <Typography color="primary">
                  {haveComment
                    ? "Cacher le"
                    : (documentData.comment ?? "") === ""
                      ? "Ajouter un"
                      : "Afficher le"}{" "}
                  commentaire
                </Typography>
              </Box>
            )}
          </Grid>
        </CardContent>
      </Card>
    </>
  );
}
