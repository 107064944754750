import * as React from "react";
import {
  Routes,
  Route,
  BrowserRouter
} from "react-router-dom";
import Login from "./pages/Login";
import AuthProvider from "./contexts/auth/AuthProvider";
import RequireAuth from "./contexts/auth/RequireAuth"; import AuthenticatedRouter from "./routers/AuthenticatedRouter";

export default function App() {


  return (
    <BrowserRouter>
      <AuthProvider>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/dashboard/*" element={
            <RequireAuth>
              <AuthenticatedRouter />
            </RequireAuth>
          } />
        </Routes>
      </AuthProvider>
    </BrowserRouter>
  );
}