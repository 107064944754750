import * as React from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';

const CustomAlert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref,
) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function Alert({ alert }: CustomAlertProps) {
    const [open, setOpen] = React.useState<boolean>(true)
    
    React.useEffect(() => {
        setOpen(true)
    }, [alert])

    const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        setOpen(false)
        if (reason === 'clickaway') {
            return;
        }
    };

    return (
        <Snackbar open={open} autoHideDuration={4000} onClose={handleClose}>
            <CustomAlert onClose={handleClose} severity={alert.variant} sx={{ width: '100%' }}>
                {alert.message}
            </CustomAlert>
        </Snackbar>

    );
}