import * as React from 'react';
import Box from '@mui/material/Box';
import { DataGrid, GridColDef, GridFooter, GridFooterContainer, GridRenderCellParams, GridRowId, GridSlotsComponentsProps, GridToolbarContainer, GridToolbarQuickFilter, GridValueGetterParams } from '@mui/x-data-grid';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, FormControl, Grid, TextField, Typography/*, styled*/ } from '@mui/material';
import { callApi, callApiFetch } from '../utils/request';
import { Link, useNavigate } from 'react-router-dom';
import Alert from '../components/Alert';

import DownloadIcon from '@mui/icons-material/Download';

import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
// import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

import { CsvToCal } from '../utils/csvtocal';

import moment from 'moment';
import 'moment-timezone';
import 'moment/locale/fr';

import './ContractsList.css';

const columns: GridColDef[] = [
  {
    field: 'ref',
    headerName: 'Contrat',
    description: 'Ref contrat',
    minWidth: 130,
    maxWidth: 130,
    flex: 1
  },
  {
    field: 'fullName',
    headerName: 'Formateur',
    description: 'Nom complet formateur',
    minWidth: 220,
    maxWidth: 220,
    valueGetter: (params: GridValueGetterParams) =>
      `${params.row.trainerFirstName || ''} ${params.row.trainerLastName || ''}`,
    flex: 1
  },
  {
    field: 'societySocialName',
    headerName: 'Société',
    description: 'Nom de la société',
    minWidth: 220,
    maxWidth: 220,
    flex: 1,
    renderCell(params) {
    return (
      <>
        {params.row.societySocialName ? params.row.societySocialName : 
          (
            <>
              <Typography sx={{ fontSize: "10px", display: "inline-block", verticalAlign: "middle", marginRight: "4px", color: "grey" }}>
                (individuel)
              </Typography>
              <Typography  sx={{ fontSize: "inherit" }}>
                {`${params.row.signatoryFirstName} ${params.row.signatoryLastName}`}
              </Typography>
            </>
          )
        } 
      </>
    )}
  },
  {
    field: 'signatoryEmail',
    headerName: 'Email',
    minWidth: 180,
    maxWidth: 260,
    flex: 1,
    renderCell(params) {
      return <Link to={`mailto:${params.row.signatoryFirstName} ${params.row.signatoryLastName} <${params.row.signatoryEmail}>?subject=[LessonSharing - Contrat #${params.row.ref}]%20`}>{params.row.signatoryEmail}</Link>
    },
  },
  {
    field: 'label',
    headerName: 'Mission',
    flex: 1,
    // valueGetter: ({ row }) => `${row.Label?.label || ''}`
  },
  {
    field: 'state',
    headerName: 'État',
    minWidth: 200,
    maxWidth: 200,
    flex: 1,
    renderCell: (params: GridRenderCellParams) => getStateIcon(params.row.signed, params.row.signreq, params.row.state)
  },
  {
    field: 'filesSentState',
    headerName: '🐱',
    minWidth: 60,
    maxWidth: 60,
    renderCell: (params: GridRenderCellParams) => getFileSentState(params.row.filesSent, params.row.filesCount)
  }
];

function getFileSentState(sent:number, total:number) {
  return <><div className={`filesentstate state-${sent === 0 ? 0 : sent === total ? 1 : 2}`}></div></>;
}

function getStateIcon(signed: boolean, signpen: string, state: StateContractEnumType) {
  switch (state) {
    case "ongoing":
      return <Typography sx={{ color: '#CC5500', fontSize:'inherit' }}>En cours</Typography>

    case "enclosed":
      return <Typography sx={{ color: '#8f8f8f', fontSize:'inherit' }}>Terminé (payée)</Typography>

    case "compliant":
      return <Typography sx={{ color: '#66bb6a', fontSize:'inherit' }}>En attente de payment</Typography>

    case "pending":
      if (signed) {
        return <Typography sx={{ color: '#29b6f6', fontSize:'inherit' }}>[signé] À traiter</Typography>
      } else if (signpen) {
        return <Typography sx={{ color: '#C400CC', fontSize:'inherit' }}>Signature en cours</Typography>
      } else {
        return <Typography sx={{ color: '#CC5500', fontSize:'inherit' }}>Non signé</Typography>
      }

    case "notcompliant":
      return <Typography sx={{ color: '#f44336', fontSize:'inherit' }}>Non conforme</Typography>

    case "canceled":
      return <Typography sx={{ color: '#f44336', fontSize:'inherit' }}>Annulé</Typography>
  }
}

// const VisuallyHiddenInput = styled('input')({
//   clip: 'rect(0 0 0 0)',
//   clipPath: 'inset(50%)',
//   height: 1,
//   overflow: 'hidden',
//   position: 'absolute',
//   bottom: 0,
//   left: 0,
//   whiteSpace: 'nowrap',
//   width: 1,
// });

function ContractsList() {
  const [data, setData] = React.useState<ContractType[]>([])
  const [selectedContractId, setSelectedContractId] = React.useState<GridRowId | null>(null)
  const [confirm, setConfirm] = React.useState<boolean>(false)
  const [sendInvitsModalOpen, setSendInvitsModalOpen] = React.useState<boolean>(false)
  const [exportCalModalOpen, setExportCalModalOpen] = React.useState<boolean>(false)
  const [alert, setAlert] = React.useState<CustomAlertType | null>(null)
  const [refresh, setRefresh] = React.useState<boolean>(false)
  const [confirmCancelModalOpen, setConfirmCancelModalOpen] = React.useState<boolean>(false)
  const [createAmendmentModalOpen, setCreateAmendmentModalOpen] = React.useState<boolean>(false)
  const navigate = useNavigate()

  const selectedContract = 0 < data.length ? data.filter((c) => c.id === selectedContractId)[0] : null;

  const CustomFooterComponent = (
    props: NonNullable<GridSlotsComponentsProps['footer']>,
  ) => {
    return (
      <GridFooterContainer sx={{ justifyContent: selectedContractId ? 'space-between' : 'end' }}>
        {
          selectedContract && selectedContractId
            ?
            <Box>
              <Button
                variant='outlined'
                size='small'
                sx={{ ml: 1 }}
                onClick={() => {
                  const url = `${process.env.REACT_APP_API_URL}/files/contracts/${selectedContractId}/${selectedContractId}.pdf?token=${JSON.parse(localStorage.getItem('user') as string)?.token}`
                  window.open(url, `Contract prestation formation ${selectedContract.ref}.pdf`, 'titlebar=yes,location=yes')
              }}
              >
                Ouvrir
              </Button>
              <Button
                variant='outlined'
                color="secondary"
                size='small'
                sx={{ ml: 1 }}
                onClick={() => {
                  fetch(process.env.REACT_APP_API_URL + `/files/contracts/${selectedContractId}/${selectedContractId}.pdf`,
                      {
                          method: 'GET',
                          headers: {
                              'Authorization': JSON.parse(localStorage.getItem('user') as string)?.token
                          }
                      }).then(res => res.blob())
                      .then(
                          (file: any) => {
                              const fileURL = window.URL.createObjectURL(file)
                              const link = document.createElement('a')
                              link.href = fileURL
                              link.download = `Contrat prestation formation ${selectedContract.ref}.pdf`
                              link.click()
                              link.parentNode?.removeChild(link)
                          }
                      )
              }}
              >
                Télécharger
              </Button>
            </Box> 
            : null
        }
        {
          selectedContract?.state === 'compliant'
            ? <>
              <Button
                color={confirm ? 'warning' : 'success'}
                variant='outlined'
                size='small'
                sx={{ ml: 1, mr: 'auto' }}
                onClick={() => {
                  if (!confirm) {
                    setConfirm(true)
                  } else {
                    callApi('post', `/manage/contract/generated/${selectedContract.id}/update-state`, {
                      state: 'enclosed'
                    }).then((r) => {
                      if (r.alert) {
                        setAlert(r.alert)
                        if (r.alert.variant === "success") {
                          selectedContract.state = 'enclosed'
                          setConfirm(false)
                        }
                      }
                    }, () => { })
                  }

                }}
              >
                {confirm ? "Confirmer la clôture du contrat" : "Indiquer comme payé"}
              </Button>
            </>
            : null
        }
        {
          !selectedContract?.signed
            ? <>
                {selectedContract?.signreq === null
                ?
                <Button
                    color='success'
                    variant='outlined'
                    size='small'
                    sx={{ ml: 1, mr: 'auto' }}
                    onClick={() => {
                    setSendInvitsModalOpen(true)
                    }}
                >
                    Signer
                </Button>
                : ""
                }

              <Button
                color="error"
                variant="outlined"
                size="small"
                sx={selectedContract?.signreq ? { ml:'auto' } : {}}
                onClick={() => {
                  callApi("delete", `/manage/contract/generated/${selectedContractId}`).then((r) => {
                    if (r.alert) {
                      setAlert(r.alert)
                      if (r.alert.variant === "success") {
                        setRefresh(!refresh)
                      }
                    }
                  })
                }}
              >
                Supprimer
              </Button>
            </>
            : (selectedContract?.signed && -1 === ['compliant', 'canceled', 'enclosed'].indexOf(selectedContract.state))
              ? <>
                  <Button 
                  sx={{ ml: 'auto', mr: 1 }} 
                  color='warning' 
                  variant='outlined' 
                  size='small'
                  onClick={() => setCreateAmendmentModalOpen(true)}
                >
                  Créer un avenant
                </Button>

                <Button 
                  sx={{ mr: 1 }} 
                  color='error' 
                  variant='outlined' 
                  size='small'
                  onClick={() => setConfirmCancelModalOpen(true)}
                >
                  Annuler le contrat
                </Button>
                </>
              : null
        }
        <GridFooter sx={{ border: 'none' }} />
      </GridFooterContainer>
    );
  }

  const CustomToolbarComponent = () => {
    return (
      <GridToolbarContainer>
        <GridToolbarQuickFilter
                sx={{ m: 0.8, width:250 }}
                quickFilterParser={(value) => {
                    localStorage.removeItem("PageEditedContracts")
                    value ? localStorage.setItem("filterEditedContracts", value)
                          : localStorage.removeItem("filterEditedContracts")
                    return [value]
                }}
            />

        <Button
            // color={confirm ? 'warning' : 'success'}
            // variant='outlined'
            size='small'
            endIcon={<DownloadIcon />}
            // sx={{ ml: 1, mr: 'auto' }}
            onClick={() => setExportCalModalOpen(true)}
          >
              Exporter
          </Button>
      </GridToolbarContainer>
    )
  }

    React.useEffect(() => {
        callApi(
            'get',
            '/manage/contract/generated/search'
        ).then(r => setData(r), () => {})
    }, [refresh])

    return (
        <Box sx={{ height: 'calc(100vh - 64px - 24px * 5)', width: '100%' }}>
            <Typography variant='h6'>Contrats établis</Typography>
            <Divider sx={{ my: 2 }} />

            {/* Affiche les alertes. */}
            {alert && <Alert alert={alert}/>}
            
            {/* Modal pour l'envoi d'une invitation à signature. */}
            {selectedContractId && <SendSigneInvitations open={sendInvitsModalOpen} setOpen={setSendInvitsModalOpen} id={selectedContractId as string} setAlert={setAlert} refresh={refresh} setRefresh={setRefresh} />}
            {/* Modal pour l'annulation d'un contrat. */}
            {selectedContractId && <ConfirmCancel open={confirmCancelModalOpen} setOpen={setConfirmCancelModalOpen} id={selectedContractId as string} setAlert={setAlert} refresh={refresh} setRefresh={setRefresh} />}
            {/* Modal pour l'export d'un calendrier. */}
            {<ExportCal open={exportCalModalOpen} setOpen={setExportCalModalOpen} setAlert={setAlert} />}
            {/* Modal pour la création d'un avenant. */}
            {<CreateAmendment open={createAmendmentModalOpen} setOpen={setCreateAmendmentModalOpen} id={selectedContractId as string} cref={selectedContract?.ref as string} setAlert={setAlert} />}
            
            <DataGrid
                sx={{ width: {sm: "100%", /*md: "90%", lg: "95%", xl: "90%",*/ xxl:"90%"} }}
                onRowDoubleClick={params => { selectedContract?.state !== "canceled" && navigate(`/dashboard/contract/${params.row.id}`) }}
                rows={data}
                columns={columns}
                slots={{ toolbar: CustomToolbarComponent, footer: CustomFooterComponent }}
                slotProps={{
                    toolbar: { showQuickFilter: true }
                }}
                initialState={{
                    pagination: {
                        paginationModel: {
                            pageSize: 5,
                            page: parseInt(localStorage.getItem("PageEditedContracts") || "", 10) || 0
                        }
                    },
                    filter: {
                        filterModel: {
                          items: [],
                          quickFilterValues: [localStorage.getItem("filterEditedContracts") || ""]
                        }
                      }                    
                }}
                onPaginationModelChange={p => localStorage.setItem("PageEditedContracts", `${p.page}`)}
                onRowSelectionModelChange={rowSelection => setSelectedContractId(rowSelection[0])}
                // checkboxSelection
                // disableRowSelectionOnClick
                autoPageSize
            />
        </Box>
    );
}

function SendSigneInvitations({open, setOpen, id, setAlert, refresh, setRefresh}: SendSigneInvitationsContractModalProps) {
    const handleClose = () => setOpen(false)

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            >
            <DialogTitle id="alert-dialog-title">Envoyer une invitation YouSign</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Merci de confirmer votre souhait de créer une invitation de signature via YouSign.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Annuler</Button>
                {/* <Button component="label" variant="text" color="success" startIcon={<CloudUploadIcon />} autoFocus> */}
                <Button
                    component="label"
                    variant="text"
                    color="success"
                    autoFocus
                    onClick={() => {
                        handleClose()
                        callApiFetch(
                            'post',
                            `/manage/contract/generated/signed/${id}`,
                            ""
                        ).then((res: any) => {
                            if (res.alert) {
                                setAlert(res.alert)
                                if (res.alert.variant === 'success') setRefresh(!refresh)
                            }
                        })
                    }}
                    >
                    Envoyer
                </Button>
            </DialogActions>
        </Dialog>
    )
}

function ConfirmCancel({open, setOpen, id, setAlert, refresh, setRefresh}: SendSigneInvitationsContractModalProps) {
  const handleClose = () => setOpen(false)
  const [cancelReason, setCancelReason] = React.useState<{cancelReason: string}>({cancelReason: ""})

//   React.useEffect(() => {
//     setCancelReason({...cancelReason, cancelReason: ""})
//   }, [])

  return (
      <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          >
          <DialogTitle id="alert-dialog-title">Confirmation d'annulation de contrat</DialogTitle>
          <DialogContent>
              <DialogContentText id="alert-dialog-description">
                  Ce contrat est signé, etes vous sur de vouloir l'annuler ? Cette action est irréversible et enverra un avenant d'annulation.
              </DialogContentText>
              <TextField 
                sx={{ my: 2 }}
                type='text'
                fullWidth
                value={cancelReason.cancelReason}
                onChange={(e) => {setCancelReason({cancelReason: e.target.value})}}
                multiline={true}
                rows={15}
              />
          </DialogContent>
          <DialogActions>
              <Button onClick={handleClose}>Annuler</Button>
              {/* <Button component="label" variant="text" color="success" startIcon={<CloudUploadIcon />} autoFocus> */}
              <Button
                  component="label"
                  variant="text"
                  color="success"
                  autoFocus
                  onClick={() => {
                    console.log(cancelReason)
                      if (!cancelReason.cancelReason) {
                        return setAlert({variant: "warning", message: "La raison d'annulation est obligatoire, elle sera mentionnée sur l'avenant d'annulation."})
                      }
                      handleClose()
                      callApi("post", `/manage/contract/generated/cancel/${id}`, cancelReason)
                      .then((res: any) => {
                          if (res.alert) {
                              setAlert(res.alert)
                              if (res.alert.variant === 'success') setRefresh(!refresh)
                          }
                      })
                  }}
                  >
                  Envoyer
              </Button>
          </DialogActions>
      </Dialog>
  )
}

function CreateAmendment({open, setOpen, id, cref, setAlert}:any) {
  const handleClose = () => {
    setOpen(false)
    setCal("")
    setEditMod(0)
    setPriceUnit("H")
    setPrice(0)
  };

  const MOD_UPDATE_TRAINER  = 0b001,
        MOD_UPDATE_PRICING  = 0b010,
        MOD_UPDATE_PLANIF   = 0b100;

  const [loading, setLoading] = React.useState<boolean>(false);
  const [cal, setCal] = React.useState<string>("");
  const [editMod, setEditMod] = React.useState<number>(0);
  const [priceUnit, setPriceUnit] = React.useState<string>("H");
  const [price, setPrice] = React.useState<number>(0);

  const priceUnitMap:{[key:string]: string} = {
    H: "Heure",
    J: "Jour",
    F: "Forfait"
  };

  return (
      <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          >
          <DialogTitle id="alert-dialog-title">Créer un avenant</DialogTitle>
          <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {loading ? <Typography>Chargment...</Typography>
                :
                <>
                <Typography sx={{mb:2}}>Veuillez indiquez les raisons de création d'avenant :</Typography>
                <FormGroup>
                  <FormControlLabel control={<Checkbox checked={0 !== (editMod & MOD_UPDATE_PRICING)} onChange={e => setEditMod(editMod ^ MOD_UPDATE_PRICING)} />} label={<Typography>Modification tarif</Typography>} />
                  {0 < (editMod & MOD_UPDATE_PRICING)
                      ?
                      <>
                      <Box>
                        <Grid spacing={2}>
                          <TextField
                            id="export-society"
                            label="Montant (HT €)"
                            size='small'
                            variant='standard'
                            inputProps={{type: 'number', min:1}}
                            value={price > 0 ? price : null}
                            sx={{mt:1, mb:2, width:150, verticalAlign:'middle'}}
                            onChange={e => setPrice(parseFloat(e.target.value) || 0)}
                          />

                            <ToggleButtonGroup
                              value={priceUnit}
                              exclusive
                              size='small'
                              color='primary'
                              sx={{ml:2, mb:2, verticalAlign:'middle'}}
                              onChange={(_, v) => setPriceUnit(v)}
                              aria-label="price  unit"
                            >
                              <ToggleButton value="H" aria-label="centered">{priceUnitMap["H"]}</ToggleButton>
                              <ToggleButton value="J" aria-label="left aligned">{priceUnitMap["J"]}</ToggleButton>
                              <ToggleButton value="F" aria-label="right aligned">{priceUnitMap["F"]}</ToggleButton>
                            </ToggleButtonGroup>
                          </Grid>
                      </Box>
                      {0 < price && <Typography sx={{mb:2, fontSize:12}}>{"F" === priceUnit ? `Forfait de ${price} € HT` : `Tarif de ${price} € HT / ${priceUnitMap[priceUnit].toLowerCase()}`}</Typography>}
                      </>
                      : <></>}
                  <FormControlLabel control={<Checkbox checked={0 !== (editMod & MOD_UPDATE_TRAINER)} onChange={e => setEditMod(editMod ^ MOD_UPDATE_TRAINER)} />} label={<Typography>Modification de formateur</Typography>} />
                  <FormControlLabel control={<Checkbox checked={0 !== (editMod & MOD_UPDATE_PLANIF)}  onChange={e => setEditMod(editMod ^ MOD_UPDATE_PLANIF)} />}  label={<Typography>Modification de la planification</Typography>} />
                  {0 < (editMod & (MOD_UPDATE_TRAINER | MOD_UPDATE_PLANIF))
                      ?
                      <>
                      <Typography sx={{mt:2, fontSize:12}}>Nouveau planning</Typography>
                      <TextField 
                        sx={{mt:1}}
                        type='text'
                        fullWidth
                        onChange={e => setCal(e.target.value)}
                        multiline={true}
                        value={cal}
                        placeholder='Inserez le nouveau planning'
                        rows={15}
                      />
                      </>
                      : <></>}
                </FormGroup>
                </>
                }
              </DialogContentText>
          </DialogContent>
          <DialogActions>
              <Button onClick={handleClose}>Annuler</Button>
              <Button
                  component="label"
                  variant="text"
                  color="success"
                  autoFocus
                  onClick={() => {
                    // console.log("Mod:", editMod.toString(2).padStart(3, "0"));
                    // console.log("Price: %s / %s", price, priceUnitMap[priceUnit].toLowerCase());
                    // console.log("Planning:", cal);
                    // console.log("cref:", cref);

                    if ((editMod & (MOD_UPDATE_PLANIF | MOD_UPDATE_TRAINER)) && !cal) {
                      return setAlert({variant: "warining", message: "Vous devez indiquer le planning."});
                    }

                    if ((editMod & MOD_UPDATE_PRICING) && 0 >= price) {
                      return setAlert({variant: "warining", message: "Vous devez indiquer le nouveau tarif."});
                    }

                    const calobj = CsvToCal(cal);
                    if (calobj instanceof Error) {
                        return setAlert({ variant: "warning", message: calobj.toString() });
                    } else if ((editMod & (MOD_UPDATE_PLANIF | MOD_UPDATE_TRAINER)) && 0 === calobj.cnt_planif.length) {
                        return setAlert({ variant: "warning", message: "Le nouveau planning semble être vide." });
                    }

                    setLoading(true);

                    let data = {};
                    data = {...data, cnt_reason:editMod};
                    data = (editMod & (MOD_UPDATE_PLANIF | MOD_UPDATE_TRAINER)) ? {...data, cnt_planif:calobj.cnt_planif} : data;
                    data = (editMod & MOD_UPDATE_PRICING) ? {...data, cnt_price:price, cnt_price_unit:priceUnitMap[priceUnit].toLowerCase()} : data;

                    fetch(`${process.env.REACT_APP_API_URL}/manage/cal/${id}`, {
                      method: 'PUT',
                      headers: {
                        'Authorization': JSON.parse(localStorage.getItem('user') as string)?.token || null,
                        'Content-Type': 'application/json',
                      },
                      body: JSON.stringify(data)
                    }).then(res => res.json())
                    .then(result => {
                        if (result.alert) {
                            setAlert(result.alert);

                            if (result.alert.variant === "success") {
                                fetch(process.env.REACT_APP_API_URL + `/files/contracts/${id}/${result.filename}`, {
                                    method: 'GET',
                                    headers: {
                                        'Authorization': JSON.parse(localStorage.getItem('user') as string)?.token
                                    }
                                }).then(res => res.blob())
                                .then((file: any) => {
                                    const link = document.createElement('a');
                                    link.href = window.URL.createObjectURL(file);
                                    link.download = `Avenant ${moment().format("YYMMDD")} au contrat de prestation ${cref}.pdf`;
                                    link.click();
                                    link.parentNode?.removeChild(link);
                                });

                                setTimeout(() => {
                                    handleClose();
                                    setTimeout(() => setLoading(false), 1000);
                                }, 2000);
                            } else {
                                setLoading(false);
                            }
                        } else {
                            setLoading(false);
                            return setAlert({ variant: "error", message: "Une erreur s'est produite." });
                        }
                    }).catch(err => {
                        setLoading(false);
                        setAlert({variant: "error", message: "Erreur: " + err})
                    });
                   }}
                  >
                  Envoyer
              </Button>
          </DialogActions>
      </Dialog>
  )
};

function ExportCal({open, setOpen, setAlert}:any) {
  const handleClose = () => {
    setOpen(false)
    setExportType("")
    setCsv(false)
    setCalSoc("")
    setCalTlname("")
    setCalTfname("")
  };

  const [loading, setLoading] = React.useState<boolean>(false);
  const [exportType, setExportType] = React.useState<String>("");
  const [csv, setCsv] = React.useState<boolean>(false);
  const [calSoc, setCalSoc] = React.useState<String>("");
  const [calTlname, setCalTlname] = React.useState<String>("");
  const [calTfname, setCalTfname] = React.useState<String>("");

  return (
      <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          >
          <DialogTitle id="alert-dialog-title">Exporter un calendrier</DialogTitle>
          <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {loading ? <Typography>Chargment...</Typography>
                :
                <FormGroup>
                  <FormControl>
                    <FormLabel id="radio-group-exporttype">Exporter pour</FormLabel>
                    <RadioGroup
                      aria-labelledby="radio-group-exporttype"
                      defaultValue="female"
                      name="radio-buttons-group"
                    >
                      <FormControlLabel value="society" control={<Radio checked={exportType === "society"} onChange={e => setExportType(e.target.value)} />} label="Société" />
                      {exportType === "society"
                      ?
                      <>
                         <TextField
                            id="export-society"
                            label="Nom de la société"
                            size='small'
                            variant='standard'
                            value={calSoc}
                            sx={{mt:1, mb:2}}
                            onChange={e => setCalSoc(e.target.value)}
                          />
                      </>
                      : <></>}
                      <FormControlLabel value="trainer" control={<Radio checked={exportType === "trainer"} onChange={e => setExportType(e.target.value)} />} label="Formateur" />
                      {exportType === "trainer"
                      ?
                      <>
                        <TextField
                            id="export-trainer-fname"
                            label="Prénom"
                            size='small'
                            variant='standard'
                            value={calTfname}
                            sx={{mt:1}}
                            onChange={e => setCalTfname(e.target.value)}
                          />

                          <TextField
                            id="export-trainer-lname"
                            label="Nom de famille"
                            size='small'
                            variant='standard'
                            value={calTlname}
                            sx={{mt:1}}
                            onChange={e => setCalTlname(e.target.value)}
                          />
                      </>
                      : <></>}
                    </RadioGroup>
                  </FormControl>
                  <FormControlLabel control={<Checkbox size="small" checked={csv} onChange={e => setCsv(e.target.checked)} />} sx={{marginTop:4, fontSize:6}} label={<Typography sx={{fontSize:15}}>Exporter en CSV</Typography>} />
                </FormGroup>
                }
              </DialogContentText>
          </DialogContent>
          <DialogActions>
              <Button onClick={handleClose}>Annuler</Button>
              <Button
                  component="label"
                  variant="text"
                  color="success"
                  autoFocus
                  onClick={() => {
                    if (exportType === "society" && !calSoc) {
                      return setAlert({variant: "warining", message: "Vous devez indiquer un nom de société."});
                    }

                    if (exportType === "trainer" && (!calTlname || !calTfname)) {
                      return setAlert({variant: "warining", message: "Prénom ou nom du formateur manquant."});
                    }

                    setLoading(true);

                    let data = {};
                    data = exportType === "society" ? {...data, cal_soc: calSoc} : data;
                    data = exportType === "trainer" ? {...data, cal_tlname: calTlname, cal_tfname:calTfname} : data;
                    data = csv ? {...data, cal_csv: csv} : data;

                    fetch(`${process.env.REACT_APP_API_URL}/manage/cal`, {
                      method: 'POST',
                      headers: {
                        'Authorization': JSON.parse(localStorage.getItem('user') as string)?.token || null,
                        'Content-Type': 'application/json',
                      },
                      body: JSON.stringify(data)
                    }).then(async res => {
                      if (res.status !== 200) {
                        setLoading(false);
                        setAlert({variant: "warning", message: res.status === 404 ? "Aucune donnée trouvée." : "Code invalide:" + res.status});
                        return;
                      }

                      const url = window.URL.createObjectURL(await res.blob()),
                            a = document.createElement('a');

                      a.href = url;
                      a.download = "Calendrier." + (csv ? "csv" : "pdf");

                      document.body.appendChild(a);
                      a.click();
                      a.parentNode?.removeChild(a);

                      handleClose();

                      setTimeout(() => setLoading(false), 1000);
                    }).catch(err => {
                      setLoading(false);
                      setAlert({variant: "error", message: "Erreur: " + err})
                    });
                  }}
                  >
                  Envoyer
              </Button>
          </DialogActions>
      </Dialog>
  )
};

export default ContractsList;

