import React, { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { callApi } from '../utils/request';
import { DataGrid, GridColDef, GridFooter, GridFooterContainer, GridRowId, GridSlotsComponentsProps, GridToolbarContainer, GridToolbarQuickFilter } from '@mui/x-data-grid';
import { Autocomplete, Box, Button, Checkbox, Dialog, DialogContent, DialogTitle, Divider, FormControlLabel, IconButton, TextField, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import Alert from '../components/Alert';
import ConfirmAction from '../components/ConfirmAction';

function DocumentsList() {
  const [documents, setDocuments] = useState<AppDocumentType[]>([])
  const [formOpened, setFormOpened] = useState<boolean>(false)
  const [editformOpened, setEditFormOpened] = useState<boolean>(false)
  const [update, setUpdate] = useState<boolean>(false)
  const [selectedDocumentId, setSelectedDocumentId] = useState<GridRowId | null>(null)
  const [alert, setAlert] = useState<CustomAlertType | null>(null)
  const [deleteConfirm, setDeleteConfirm] = useState<boolean>(false)

  const handleUpdate = () => {
    setUpdate(!update)
  }

  const handleDelete = (id: GridRowId) => {
    callApi('delete', '/manage/document/' + id).then(r => {
      if (r.alert) {
        setAlert(r.alert)
      }
    }, () => { })
  }

  useEffect(() => {
    callApi('get', '/manage/document').then(r => {
      setDocuments(r)
    }, () => { })
  }, [update])

  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 90, align: 'center' },
    {
      field: 'label',
      headerName: 'Nom du document',
      maxWidth: 260,
      flex: 1
    },
    {
      field: 'description',
      headerName: 'Description du document',
      flex: 1
    }
  ];

  const CustomToolbarComponent = () => {
    return (
      <GridToolbarContainer>
        <IconButton size='large' sx={{ width: 'auto' }} onClick={() => { setFormOpened(true) }}>
          <AddIcon />
        </IconButton>
        <GridToolbarQuickFilter />
        <DocumentForm
          open={formOpened}
          onClose={() => { handleUpdate(); setFormOpened(false) }}
          setAlert={setAlert}
        />
      </GridToolbarContainer>
    )
  }

  const CustomFooterComponent = (
    props: NonNullable<GridSlotsComponentsProps['footer']>,
  ) => {
    return (
      <GridFooterContainer sx={{ justifyContent: selectedDocumentId ? 'space-between' : 'end' }}>
        {
          selectedDocumentId
            ? <Box sx={{ display: 'flex', gap: 1 }}>
              <Button
                variant='outlined'
                size='small'
                sx={{ ml: 1 }}
                onClick={() => { setEditFormOpened(true) }}
              >
                Editer
              </Button>
              <Button
                variant='outlined'
                color='error'
                size='small'
                onClick={() => {
                  setDeleteConfirm(true)
                }}
              >
                Supprimer
              </Button>
              <ConfirmAction
                open={deleteConfirm}
                setOpen={setDeleteConfirm}
                onClose={(confirmation) => {
                  confirmation && handleDelete(selectedDocumentId)
                  confirmation && handleUpdate()
                }}
                title="Suppression de document"
                description="Etes vous sur de bien vouloir supprimer ce document ? Cette action est irréversible."
              />
            </Box>
            : null
        }
        <GridFooter sx={{ border: 'none' }} />
      </GridFooterContainer>
    );
  }

  return (
    <Box sx={{ height: 'calc(100vh - 64px - 24px * 5)', width: '100%' }}>
      <Typography variant='h6'>Documents</Typography>
      <Divider sx={{ my: 2 }} />
      <DataGrid
        sx={{ width: {sm: "100%", md: "90%", lg: "80%", xl: "70%"} }}
        rows={documents}
        columns={columns}
        autoPageSize
        slots={{
          toolbar: CustomToolbarComponent, footer: CustomFooterComponent
        }}
        onRowSelectionModelChange={(rowSelection) => {
          setSelectedDocumentId(rowSelection[0])
        }}
      />
      <DocumentForm
        open={editformOpened}
        onClose={() => { handleUpdate(); setEditFormOpened(false) }}
        currentDocument={documents.filter(i => i.id === selectedDocumentId)[0]}
        setAlert={setAlert}
      />
      {alert && <Alert alert={alert} />}
    </Box>
  );
}

function DocumentForm({ open, onClose, currentDocument = null, setAlert }: DocumentFormProps) {
  const [document, setDocument] = useState<AppDocumentType | null>(null)

  useEffect(() => {
    setDocument(currentDocument ? currentDocument : {
      label: '',
      description: '',
      default: false,
      maxSize: 5,
      filetypes: []
    })
  }, [currentDocument])

  const handleClose = () => {
    onClose();
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (document?.label && document.filetypes && document.maxSize) {
      callApi(
        currentDocument ? 'put' : 'post',
        '/manage/document/' + (currentDocument ? currentDocument.id : ''),
        document
      ).then(r => {
        handleClose()
        if (r.alert) {
          setAlert(r.alert)
        }
      }, () => { })
    }
  }

  const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setDocument({ ...document!, [e.target.name]: e.target.name === "default" ? (e.target as HTMLInputElement).checked : e.target.value })
  }

  const authorizedMimetypes = [
    {
      label: 'txt',
      mimetype: 'text/plain'
    },
    {
      label: 'pdf',
      mimetype: 'application/pdf'
    },
    {
      label: 'csv',
      mimetype: 'text/csv'
    },
    {
      label: 'zip',
      mimetype: 'application/zip'
    }
  ]

  return (
    <Dialog onClose={handleClose} open={open} sx={{ p: 3, background: 'none', boxShadow: 'none' }}>
      <DialogTitle>{currentDocument ? 'Editer' : 'Ajouter'} un document</DialogTitle>
      <DialogContent>
        <Box
          component='form'
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            pt: 1
          }}
          onSubmit={handleSubmit}
        >
          <TextField
            value={document?.label || ''}
            name='label'
            onChange={handleChange}
            label='Nom'
          />
          <TextField
            value={document?.description || ''}
            name='description'
            onChange={handleChange}
            label='Description'
          />
          <Autocomplete
            multiple
            id="tags-outlined"
            options={authorizedMimetypes}
            getOptionLabel={(option) => option.label}
            // defaultValue={[]}
            filterSelectedOptions
            value={document?.filetypes.filter((d: any) => {
              return authorizedMimetypes.some((e: any) => e.mimetype === d.mimetype)
            })}
            isOptionEqualToValue={(option, value) => option.mimetype === value.mimetype}
            onChange={(_, value) => setDocument({ ...document!, filetypes: [...value] })}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Extensions autorisées"
              />
            )}
          />
          <TextField
            type='number'
            fullWidth
            value={document?.maxSize}
            label='Taille maximale du fichier (en Mo)'
            name='maxSize'
            onChange={handleChange}
          />
          <FormControlLabel
            control={
              <Checkbox
                name='default'
                checked={document?.default}
                onChange={handleChange}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            }
            label="Document sélectionné par défaut"
          />
          <Button
            variant='outlined'
            color='success'
            type='submit'
          >
            Enregistrer
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
}

export default DocumentsList;
