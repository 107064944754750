import React from "react";
import { AuthContext } from "./AuthContext";
import { useNavigate } from "react-router-dom";

export default function AuthProvider({ children }: { children: React.ReactNode }) {
  let [user, setUser] = React.useState<any>(null);

  const navigate = useNavigate()

  React.useEffect(() => {
    if (!localStorage.getItem('user')) {
      navigate('/', { replace: true })
    }
  }, [localStorage.getItem('user')])

  const authenticated = {
    isAuthenticated: false,
    signin(callback: VoidFunction) {
      authenticated.isAuthenticated = true
      callback()
    },
    signout(callback: VoidFunction) {
      authenticated.isAuthenticated = false
      localStorage.removeItem('user')
      callback()
    }
  }

  let signin = (newUser: string, callback: VoidFunction) => {
    return authenticated.signin(() => {
      setUser(newUser);
      callback();
    });
  };

  let signout = (callback: VoidFunction) => {
    return authenticated.signout(() => {
      setUser(null);
      callback();
    });
  };

  let value = { user, signin, signout };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}