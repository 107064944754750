import { Avatar, Box, Button, CircularProgress, TextField } from '@mui/material';
import React, { ChangeEvent, SyntheticEvent, useEffect } from 'react';
import { useAuth } from '../contexts/auth/AuthContext';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Alert from '../components/Alert';
import { callApi } from '../utils/request';

function Login() {
  const [userInformations, setUserInformations] = React.useState<LoginType>()
  const auth = useAuth()
  const navigate = useNavigate()
  const location = useLocation()
  const from = location.state?.from?.pathname || "/";
  const [loading, setLoading] = React.useState<boolean>(true)
  const [alert, setAlert] = React.useState<CustomAlertType | null>(null)
  const [codeSend, setCodeSend] = React.useState<boolean>(false)

  useEffect(() => {
    if (localStorage.getItem('user')) {
      setTimeout(() =>
        auth.signin(
          JSON.parse(localStorage.getItem('user') as string),
          () => {
            navigate(from === '/' ? "/dashboard" : from, { replace: true })
          }
        ),
        500
      )
    }
    else {
      setLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setUserInformations({ ...userInformations, [e.target.name]: e.target.value })
  }

  const handleSubmit = (e: SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (!codeSend) {
      setCodeSend(true)
      callApi('post', '/auth/get-code', { 'email': userInformations?.email })
    } else {
      if (userInformations?.email && userInformations.otpCode) {
        axios.post('/auth/login', userInformations, { baseURL: process.env.REACT_APP_API_URL })
          .then((r: any) => {
            if (r.data.token) {
              localStorage.setItem('user', JSON.stringify({ email: userInformations.email, token: r.data.token }))
              localStorage.setItem('token', r.data.token)
              auth.signin(
                r.data.user,
                () => navigate(from === '/' ? "/dashboard" : from, { replace: true })
              )
            }
          })
          .catch((err) => {
            if (err.response.data.alert) {
              setAlert(err.response.data.alert)
            }
          })
      }
    }
  }

  return (
    <React.Fragment>
      <Box
        component="form"
        sx={{
          display: "flex",
          flexDirection: 'column',
          justifyContent: "center",
          height: "100vh", gap: '2em',
          maxWidth: "350px",
          margin: "auto"
        }}
        onSubmit={handleSubmit}
      >
        {
          loading
            ? <CircularProgress sx={{ margin: 'auto' }} />
            : <React.Fragment>
              <Avatar alt="LessonSharing Logo" src="/icon.png" sx={{ width:200, height: "auto", mx:"auto" }} />
              <TextField onChange={handleChange} label="Email" variant="outlined" type="email" name="email" />
              {codeSend &&
                <TextField onChange={handleChange} label="Code OTP" variant="outlined" type="text" name="otpCode" />
              }
              <Button type='submit' variant="outlined">{codeSend ? 'Connexion' : 'Continuer'}</Button>
            </React.Fragment>
        }
      </Box>
      {alert && <Alert alert={alert} />}
    </React.Fragment>
  );
}

export default Login;
